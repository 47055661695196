/*eslint-disable*/
import React from "react";

import { PresentationPageWrapper } from "./styled";
import SectionLinks from "./Sections/SectionLinks";
import SectionContact from "./Sections/SectionContact";
import SectionFooter from "./Sections/SectionFooter";


export default function ContactPage() {
  return (
    <PresentationPageWrapper>
      <SectionLinks logo selected='/contacto' />
      <SectionContact />
      <SectionFooter hideButton />
    </PresentationPageWrapper>
  );
}
