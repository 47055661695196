import * as yup from "yup";
import useForm from "../useForm";

export const fields = {
  name: {
    required: true,
    error: 'El nombre',
    label: 'Nombre',
    placeholder: '¿Cuál es tu nombre?',
  },
  email: {
    required: true,
    error: 'El email',
    label: 'Email',
    placeholder: 'Indica tu email para poder responderte',
  },
  subject: {
    required: true,
    error: 'El asunto',
    label: 'Asunto',
    placeholder: '¿De qué se trata tu consulta?',
  },
  message: {
    required: true,
    error: 'El mensaje',
    label: 'Mensaje',
    placeholder: 'Escribe tu mensaje y lo atenderé lo antes posible',
  }
};


const schemas = () => ({
  email: yup.string().email().required(),
  name: yup.string().required(),
  subject: yup.string().required(),
  message: yup.string().required(),
});

const useContactForm = () => {
  return useForm({ fields, schemas: schemas()});
};

export default useContactForm;