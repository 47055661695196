import React from "react";

import ContactFormCompose from "compose/ContactFormCompose";
import Box from "components/layout/Box";
import images from "components/styles/images";
import { HeroStyled, SectionWrapper, TitleStyled } from "views/PresentationPage/Sections/styled";
import { ContactTitleShadowStyled, ContactTitleStyled } from "views/PresentationPage/Sections/contact/styled";
import { CardContactStyled, CardBodyContactStyled } from "views/Cards/styled";

export default function SectionContact() {

  return (
    <>
      <HeroStyled height='40' image={images.bg.rope} color="info" intensity='' shade={0.7}>
        <ContactTitleStyled>
          ¿Cómo te puedo ayudar?
        </ContactTitleStyled>
        <ContactTitleShadowStyled>
          ¿Cómo te puedo ayudar?
        </ContactTitleShadowStyled>
      </HeroStyled>
      <SectionWrapper>
        <Box>
          <CardContactStyled>
            <TitleStyled>Contacto</TitleStyled>
            <img src={images.pic.fastEmail} />
            <CardBodyContactStyled>
              <ContactFormCompose />
            </CardBodyContactStyled>
          </CardContactStyled>
        </Box>
      </SectionWrapper>
    </>
  );
}

