import styled, { css } from 'styled-components';
import { grayColor, logoColor, primaryColor, secondaryColor, whiteColor } from 'components/styles/constants';
import { TextField } from 'components/form/TextField';

const breakpointDown = point => str => ({ theme }) => 
  css`
      ${theme.breakpoints.down(point)}{
        ${str}
      }
    `


export const ContactTitleStyled = styled.h2`
  position: absolute;
  color: ${whiteColor};
  font-size: 3rem;
  ${breakpointDown('xs')`
    font-size: 2rem;
  `}
  font-weight: bold;
  margin: 0 auto;
  top: 0;
  z-index: 1;
`
export const ContactTitleShadowStyled = styled(ContactTitleStyled)`
  color: ${grayColor[1]};
  top: 3px;
  left: 3px;
  z-index: 0;
`
