import React, { useState } from "react";

import useContactForm from "../use/form/impl/useContactForm";
import Box from "components/layout/Box";


import Form from "components/form/form/Form"
import { SubmitButtonStyled, TextFieldStyled } from "components/form/form/styled";
import { TextAreaStyled } from "components/form/form/styled";
import { Alert } from "components/message/Alert";

const contactURL = '/contact.php'
const defaultValues = {
  name: '',
  email: '',
  subject: '',
  message: ''
}

async function fetchUrlJSON(url, params) {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  });
  const movies = await response.json();
  return movies;
}

const defaultAlert = {
  open: false,
  success: false,
  message: ''
}
const alertSuccess = {
  open: true,
  success: true,
  message: 'Tu mensaje se ha enviado correctamente'
}
const alertError = {
  open: true,
  success: false,
  message: 'Se ha producido un error. Vuelve a intentarlo más tarde'
}

const ContactFormCompose = () => {
  const { form, disabled, fieldProps } = useContactForm();
  const [ alert, setAlert ] = useState(defaultAlert)
  const [ loading, setLoading ] = useState(false)
  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const { status } = await fetchUrlJSON(contactURL, form.getValues())
    
    form.reset(defaultValues)
    setAlert(status === 'success' ? alertSuccess : alertError)
    setLoading(false)
  }
  const handleClose = () => {
    setAlert(defaultAlert)
  }

  return (
    <>
      <Form form={form} onSubmit={onSubmit} defaultValues={defaultValues}>
        <TextFieldStyled {...fieldProps.name} />
        <TextFieldStyled {...fieldProps.email} />
        <TextFieldStyled {...fieldProps.subject} />
        <TextAreaStyled {...fieldProps.message} />
        <Box>
          <SubmitButtonStyled onClick={onSubmit} disabled={loading || disabled}>
            Enviar
          </SubmitButtonStyled>
        </Box>
      </Form>
      <Alert
        success={alert.success}
        openAlert={alert.open}
        text={<span>{alert.message}</span>}
        handleClose={handleClose}
      />
    </>
  )
}

export default ContactFormCompose;


/*
const ContactForm = ({ onSubmit }) => {
  // const { name, email, subject, message } = fields;
  const { handleSubmit, register, watch, control } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        render={
          ({ field }) => <Select {...field}>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20} selected>Twenty</MenuItem>
          </Select>
        }
        control={control}
        name="select"
      />
      
      <Controller
        render={
          ({ field }) => <TextFieldStyled label='texto' {...field} />
        }
        control={control}
        name="text"
        // defaultValue={10}
      />

      <input type="submit" />
    </form>
  );
}



const ContactForm = ({ fields, fieldProps, errors, handleSubmit }) => {
  const { name, email, subject, message } = fields;

  console.log('ERRORS', errors);
  
  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem md={6} sm={6}>
          <TextField label={labels.name.placeholder} 
            {... fieldProps[name]}
            errorMessage={errors[name]}/>
        </GridItem>
        <GridItem md={6} sm={6}>
          <TextField label={labels.email.placeholder} 
            {... fieldProps[email]}
            errorMessage={errors[email]}/>
        </GridItem>
        <GridItem md={12} sm={12}>
          <TextField label={labels.subject.placeholder} 
            {... fieldProps[subject]}
            errorMessage={errors[subject]}/>
        </GridItem>
        <GridItem md={12} sm={12}>
          <TextareaStyled
            label={labels.message.placeholder}
            {... fieldProps[message]}
            errorMessage={errors[message]}
            />
        </GridItem>
        <Box>
          <Button color="primary">
            Enviar
          </Button>
        </Box>
      </GridContainer>
    </form>
  );
}

*/